@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

html,
body {
  height: 100%;
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Inter", sans-serif;
}