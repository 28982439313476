/* General styles for calendar tiles */
.react-calendar {
  border: none;
  box-shadow: none;
  /* Optional: Remove shadow if there is any */
}


.react-calendar__navigation {
  border-top: 2px solid #e7e7e7;
  border-bottom: 2px solid #e7e7e7;
}

.react-calendar__tile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  /* Adjust the padding as needed */
}

/* Hide the default date number on highlighted dates */
.react-calendar__tile.hide-default abbr {
  visibility: hidden;
}

/* Custom highlight: white rectangle with a blue circle */
.custom-highlight {
  position: absolute;
  /* Cover the entire tile */
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  /* Ensure the custom highlight is above the default tile */
  /* border-radius: 5px; */
  /* Optional: gives the rectangle slightly rounded corners */
}

/* Blue circle inside the rectangle */
.custom-highlight .inner-circle {
  position: absolute;
  /* top: 1px; */
  /* left: -7px; */
  width: 30px;
  /* Adjust size of the inner circle */
  height: 30px;
  /* Adjust size of the inner circle */
  background-color: blue;
  /* Blue circle */
  border-radius: 50%;
  /* Make it a perfect circle */
  z-index: 1;
  /* Ensure it is behind the date number */
}

/* Custom date number styling */
.custom-highlight .date-number {
  position: relative;
  z-index: 2;
  /* Ensure the date number is above the circle */
  /* font-size: 14px; */
  color: white;
}

/* Hover effect: Change background color on hover */
.custom-highlight:hover {
  background-color: lightblue;
  /* Change to your desired hover color */
}

.custom-highlight:hover .inner-circle {
  background-color: darkblue;
  /* Change circle color on hover */
}

.react-calendar__tile:disabled {
  color: rgb(230, 230, 230);
  background-color: white;

  /* Change text color for disabled dates */
  cursor: not-allowed;
  /* Change cursor to indicate disabled state */

}

/* Style for today's date if you want it to be more distinct */
.custom-highlight.today .inner-circle {
  background-color: #0082ba;
  /* Example color for today's date */
}

/* Green circle inside the rectangle for future dates */
.custom-highlight.future .inner-circle {
  width: 30px;
  height: 30px;
  background-color: #22C55E;
  /* Color for future dates */
  border-radius: 50%;
  z-index: 1;
}

/* Yellow circle inside the rectangle for selected date */
.custom-highlight.selected .inner-circle {
  width: 30px;
  height: 30px;
  background-color: #0009ba;
  /* Color for selected date */
  border-radius: 50%;
  z-index: 1;
}