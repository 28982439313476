/* styles.css */
/* Hide the arrow controls in input[type="number"] for Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Hide the arrow controls in input[type="number"] for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* html,
body {
  height: 100%;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
} */

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

.StripeElement {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 2px solid #797575;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .form-container {
    padding: 8px;
  }

  .StripeElement {
    padding: 10px;
    font-size: 14px;
  }
}

@media (min-width: 601px) {
  .form-container {
    padding: 20px;
  }

  .StripeElement {
    padding: 14px;
    font-size: 16px;
  }
}